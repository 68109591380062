import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-weight-light" }
const _hoisted_2 = { class: "font-weight-light" }
const _hoisted_3 = { class: "font-weight-light" }
const _hoisted_4 = { class: "font-weight-light" }
const _hoisted_5 = { class: "font-weight-light" }
const _hoisted_6 = { class: "font-weight-light" }
const _hoisted_7 = { class: "font-weight-light" }
const _hoisted_8 = { class: "font-weight-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateRangeInputField = _resolveComponent("DateRangeInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_account_ledger = _resolveComponent("account-ledger")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mt-3" }, "Trial Balance Report", -1)),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "3",
                  sm: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DateRangeInputField, {
                      modelValue: _ctx.selectedDateRange,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateRange) = $event)),
                      label: "Report Date Range",
                      "first-day-of-week": "1",
                      "hide-actions": "",
                      placeholder: "Select Report Date Range",
                      "prepend-icon": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "1",
                  sm: "1",
                  class: "mt-8"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, {
                      "offset-y": "",
                      left: "",
                      attach: ""
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({
                          small: "",
                          icon: "",
                          light: ""
                        }, props), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, {
                              icon: "",
                              cir: ""
                            }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" mdi-menu-down ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_text, { class: "pa-6" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, { flat: "" }, {
                                  default: _withCtx(() => [
                                    _cache[7] || (_cache[7] = _createTextVNode(" Options ")),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateRanges, (item) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                        key: item,
                                        value: item,
                                        title: item,
                                        onClick: ($event: any) => (_ctx.selectedRange(item))
                                      }, null, 8, ["value", "title", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  md: "2",
                  sm: "4",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "accent action-button mt-8",
                      color: "primary",
                      loading: _ctx.loading,
                      onClick: _ctx.createReport
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("mdi-sync")
                          ])),
                          _: 1
                        }),
                        _cache[9] || (_cache[9] = _createTextVNode(" Process "))
                      ]),
                      _: 1
                    }, 8, ["loading", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_data_table_server, {
              items: _ctx.items,
              headers: _ctx.headers,
              loading: _ctx.loading,
              page: _ctx.page,
              "items-per-page": _ctx.numberOfPages,
              "onUpdate:options": _cache[1] || (_cache[1] = (event) => _ctx.tableOptions = event),
              "items-length": _ctx.totalItemsCount,
              "hide-default-footer": ""
            }, {
              "item.accountName": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.accountName), 1)
              ]),
              "item.currency": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(item.currency), 1)
              ]),
              "item.accountType": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.accountType), 1)
              ]),
              "item.accountCode": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.accountCode), 1)
              ]),
              "item.totalDebit": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.GetFormatTwoDecimal(item.totalDebit, item.currency)), 1)
              ]),
              "item.totalCredit": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.GetFormatTwoDecimal(item.totalCredit, item.currency)), 1)
              ]),
              "item.balance": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.GetFormatTwoDecimal(item.balance, item.currency)), 1)
              ]),
              "item.totalBalance": _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.GetFormatTwoDecimal(item.totalBalance, item.currency)), 1)
              ]),
              "item.Actions": _withCtx(({ item }) => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_btn, {
                    class: "mt-1",
                    variant: "text",
                    size: "small",
                    title: "edit item",
                    onClick: ($event: any) => (_ctx.view(item)),
                    icon: "mdi-circle-edit-outline"
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 1
            }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.showAccountLedgerDialog)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: `accld_${_ctx.keyVersion}`,
          modelValue: _ctx.showAccountLedgerDialog,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAccountLedgerDialog) = $event)),
          persistent: "",
          onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.closeDialog()), ["esc"])),
          "max-width": "1500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_account_ledger, {
              onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog())),
              dateRange: _ctx.selectedDateRange,
              accountId: _ctx.selectedAccountId,
              currency: _ctx.selectedAccountCurrency,
              accountName: _ctx.selectedAccountName
            }, null, 8, ["dateRange", "accountId", "currency", "accountName"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}