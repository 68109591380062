import { createApp } from "vue";
import "@/extensions/prototype";
import "@mdi/font/css/materialdesignicons.css";
import "@/plugins/moment";
import router from "./router/index";
import store from "./store/index";
import App from "./App.vue";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@/assets/style/app.css";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";
import SelectField from "shared-components/src/components/Fields/SelectField.vue";
import FileUploadField from "shared-components/src/components/Fields/FileUploadField.vue";
import ComboBoxField from "shared-components/src/components/Fields/ComboBoxField.vue";
import MultiSelectCombobox from "shared-components/src/components/Fields/MultiSelectCombobox.vue";
import AutoCompleteField from "shared-components/src/components/Fields/AutoCompleteField.vue";
import PasswordField from "shared-components/src/components/Fields/PasswordField.vue";
import DateInputField from "shared-components/src/components/Fields/DateInputField.vue";
import DateRangeInputField from "shared-components/src/components/Fields/DateRangeInputField.vue";
import CurrencyTextField from "shared-components/src/components/Fields/CurrencyTextField.vue";
import CurrencyTextFieldV2 from "shared-components/src/components/Fields/CurrencyTextFieldV2.vue";
import { VCalendar } from "vuetify/labs/VCalendar";
import { VDateInput } from "vuetify/labs/VDateInput";
import NumberField from "shared-components/src/components/Fields/NumberField.vue";
import SliderField from "shared-components/src/components/Fields/SliderField.vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
    },
  },
  components: { ...components, VCalendar, VDateInput },
  directives,
});

createApp(App)
  .component("PasswordField", PasswordField)
  .component("TextField", TextField)
  .component("TextAreaField", TextAreaField)
  .component("SelectField", SelectField)
  .component("FileUploadField", FileUploadField)
  .component("ComboBoxField", ComboBoxField)
  .component("MultiSelectCombobox", MultiSelectCombobox)
  .component("AutoCompleteField", AutoCompleteField)
  .component("DateInputField", DateInputField)
  .component("DateRangeInputField", DateRangeInputField)
  .component("VueDatePicker", VueDatePicker)
  .component("CurrencyTextField", CurrencyTextField)
  .component("CurrencyTextFieldV2", CurrencyTextFieldV2)
  .component("NumberField", NumberField)
  .component("SliderField", SliderField)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount("#project-portal-app");
