<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="mt-3">Trial Balance Report</span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3" sm="4">
            <DateRangeInputField
              v-model="selectedDateRange"
              label="Report Date Range"
              first-day-of-week="1"
              hide-actions
              placeholder="Select Report Date Range"
              prepend-icon=""
            />
          </v-col>
          <v-col cols="12" md="1" sm="1" class="mt-8">
            <v-menu offset-y left attach>
              <template v-slot:activator="{ props }">
                <v-btn small icon light v-bind="props">
                  <v-icon icon cir> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text class="pa-6">
                  <v-list flat>
                    Options
                    <v-list-item v-for="item in dateRanges" :key="item" :value="item" :title="item" @click="selectedRange(item)" />
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <v-col md="2" sm="4" cols="12">
            <v-btn class="accent action-button mt-8" color="primary" :loading="loading" @click="createReport">
              <v-icon>mdi-sync</v-icon> Process
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table-server
          :items="items"
          :headers="headers"
          :loading="loading"
          :page="page"
          :items-per-page="numberOfPages"
          @update:options="(event: any) => tableOptions = event"
          :items-length="totalItemsCount"
          hide-default-footer
        >
          <template v-slot:item.accountName="{ item }">
            <span class="font-weight-light">{{ item.accountName }}</span>
          </template>
          <template v-slot:item.currency="{ item }">
            <span class="font-weight-light">{{ item.currency }}</span>
          </template>
          <template v-slot:item.accountType="{ item }">
            <span class="font-weight-light">{{ item.accountType }}</span>
          </template>
          <template v-slot:item.accountCode="{ item }">
            <span class="font-weight-light">{{ item.accountCode }}</span>
          </template>
          <template v-slot:item.totalDebit="{ item }">
            <span class="font-weight-light">{{ GetFormatTwoDecimal(item.totalDebit, item.currency) }}</span>
          </template>
          <template v-slot:item.totalCredit="{ item }">
            <span class="font-weight-light">{{ GetFormatTwoDecimal(item.totalCredit, item.currency) }}</span>
          </template>
          <template v-slot:item.balance="{ item }">
            <span class="font-weight-light">{{ GetFormatTwoDecimal(item.balance, item.currency) }}</span>
          </template>
          <template v-slot:item.totalBalance="{ item }">
            <span class="font-weight-light">{{ GetFormatTwoDecimal(item.totalBalance, item.currency) }}</span>
          </template>
          <template v-slot:item.Actions="{ item }">
            <div>
              <v-btn class="mt-1" variant="text" size="small" title="edit item" @click="view(item)" icon="mdi-circle-edit-outline" />
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
    <v-dialog
      :key="`accld_${keyVersion}`"
      v-model="showAccountLedgerDialog"
      v-if="showAccountLedgerDialog"
      persistent
      @keydown.esc="closeDialog()"
      max-width="1500"
    >
      <account-ledger
        @cancel="closeDialog()"
        :dateRange="selectedDateRange"
        :accountId="selectedAccountId"
        :currency="selectedAccountCurrency"
        :accountName="selectedAccountName"
      ></account-ledger>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AccountLedger from "./AccountLedger.vue";
import { ReportApi, TrialBalanceReportItem, TrialBalanceRequest } from "shared-components/src/services/openApi";
import moment from "moment";
import Utils from "shared-components/src/utils/Utils";
const reportApi = new ReportApi();

export default defineComponent({
  components: {
    AccountLedger,
  },
  data() {
    return {
      keyVersion: 0,
      selectedDateRange: [],
      dateRanges: ["This Week", "Last Week", "Last Month", "This Month", "Last Year", "This Year"],
      items: [] as TrialBalanceReportItem[],
      loading: true,
      showAccountLedgerDialog: false,
      selectedAccountId: undefined as string | undefined,
      selectedAccountCurrency: undefined as string | undefined,
      selectedAccountName: undefined as string | undefined,
      headers: [
        { title: "Account Name", sortable: true, align: "start", value: "accountName" },
        { title: "Currency", sortable: true, align: "start", value: "currency" },
        { title: "Account Type", sortable: true, align: "start", value: "accountType" },
        { title: "Account Code", sortable: true, align: "start", value: "accountCode" },
        { title: "Total Debit", sortable: true, align: "end", value: "totalDebit" },
        { title: "Total Credit", sortable: true, align: "end", value: "totalCredit" },
        { title: "Balance", sortable: true, align: "end", value: "balance" },
        { title: "Total Balance", align: "end", value: "totalBalance" },
        { title: "Actions", align: "center", sortable: false, value: "Actions" },
      ],
      page: 1,
      numberOfPages: 10,
      sortProp: "currency",
      sortOrder: "desc",
      tableOptions: {} as any,
      totalItemsCount: 0,
    };
  },
  methods: {
    closeDialog() {
      this.showAccountLedgerDialog = false;
    },
    GetFormatTwoDecimal(number: number, currency: string): string {
      return Utils.accountingMoneyFormat(number, currency);
    },
    async createReport() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;
        if (!this.selectedDateRange || this.selectedDateRange.length == 0) {
          this.items = [];
          return;
        }

        const model = {
          startDate: Utils.toVsDateFormat(this.selectedDateRange[0]),
          endDate: Utils.toVsDateFormat(this.selectedDateRange[this.selectedDateRange.length - 1]),
          sortOrder: this.sortOrder,
          sortProperty: this.sortProp,
        } as TrialBalanceRequest;
        const result = await reportApi.getTrialBalance(model);
        this.items = result.data;
      } finally {
        this.loading = false;
      }
    },
    selectedRange(newVal: string) {
      let startDate = new Date();
      let endDate = new Date();
      switch (newVal) {
        case "This Week":
          startDate = moment().startOf("week").toDate();
          endDate = moment().endOf("week").toDate();
          break;
        case "Last Week":
          startDate = moment().subtract(1, "weeks").startOf("week").toDate();
          endDate = moment().subtract(1, "weeks").endOf("week").toDate();
          break;
        case "Last Month":
          startDate = moment().subtract(1, "months").startOf("month").toDate();
          endDate = moment().subtract(1, "months").endOf("month").toDate();
          break;
        case "This Month":
          startDate = moment().startOf("month").toDate();
          endDate = moment().endOf("month").toDate();
          break;
        case "Last Year":
          startDate = moment().subtract(1, "years").startOf("year").toDate();
          endDate = moment().subtract(1, "years").endOf("year").toDate();
          break;
        case "This Year":
          startDate = moment().startOf("year").toDate();
          endDate = moment().endOf("year").toDate();
          break;
      }
      if (startDate < endDate) {
        (this.selectedDateRange as any) = [startDate, endDate];
      }
    },
    view(item: TrialBalanceReportItem) {
      this.keyVersion++;
      this.selectedAccountId = item.accountId;
      this.selectedAccountCurrency = item.currency;
      this.selectedAccountName = item.accountName;
      this.showAccountLedgerDialog = true;
    },
  },

  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
          this.sortOrder = newVal.sortBy[0].order;
        }

        this.createReport();
      }
    },
  },
  async mounted(): Promise<void> {
    this.selectedRange("This Year");
    await this.createReport();
  },
});
</script>
<style scoped>
.small {
  font-size: 90%;
}
</style>
