<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row no-gutters>
        <span> Set Payment Reference/Date</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close" fab class="error" x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="frmPayment" v-model="valid" lazy-validation class="ref-form">
        <v-row>
          <v-col md="5" sm="4" cols="6">
            <b>Invoice Date:</b>
          </v-col>
          <v-col md="7" sm="8" cols="6"> {{ formatDate(InvoiceFromDate) }} - {{ formatDate(InvoiceToDate) }}</v-col>
        </v-row>
        <v-row @click="fillAUDAmount()">
          <v-col md="5" sm="4" cols="6">
            <b>Total {{ currency }} Amount: </b>
          </v-col>
          <v-col md="7" sm="8" cols="6"> {{ formatNumber(InvoiceAUDTotalPay) }} {{ currency }} </v-col>
        </v-row>
        <v-row>
          <v-col md="5" sm="4" cols="6">
            <b>GST {{ currency }} Amount: </b>
          </v-col>
          <v-col md="7" sm="8" cols="6"> {{ formatNumber(InvoiceTaxTotalPay) }} {{ currency }} </v-col>
        </v-row>

        <v-row>
          <v-col md="4">
            <TextField :rules="[rules.required]" label="Payment Refrence" v-model="model.PaymentRefNo" outlined dense></TextField>
          </v-col>
          <v-col md="2">
            <v-menu v-model="paymentDatePickerMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ props }">
                <TextField
                  :rules="[rules.required]"
                  label="Payment Date"
                  v-model="formatedPaymentDate"
                  readonly
                  outlined
                  v-bind="props"
                  dense
                ></TextField>
              </template>
              <v-date-picker
                first-day-of-week="1"
                ref="picker"
                v-model="model.PaymentDate"
                @update:modelValue="paymentDatePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <CurrencyTextFieldV2
              :rules="[rules.required, rules.currency, rules.notZero]"
              dense
              outlined
              :label="`Amount ${currency}`"
              v-model="model.AUDPaymentAmount"
              :readonly="isReadOnly"
            />
          </v-col>
          <v-col md="2">
            <CurrencyTextFieldV2
              :rules="[rules.required, rules.currency]"
              dense
              outlined
              :label="`GST ${currency}`"
              v-model="model.TaxPaymentAmount"
              :readonly="isReadOnly"
            />
          </v-col>

          <v-col md="2" sm="2" cols="12">
            <v-btn fab x-small color="primary" class="mt-8" @click="confirmAddPayment"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-card-text v-if="InvoicePayments">
        <v-data-table :headers="getColumns()" :items="InvoicePayments" item-key="id" class="elevation-1 custom-table-row">
          <template v-slot:item.PaymentDate="{ item }">
            {{ formatDate(item.PaymentDate) }}
          </template>
          <template v-slot:item.PaymentRefNo="{ item }">
            {{ item.PaymentRefNo }}
          </template>
          <template v-slot:item.AUDTotalPay="{ item }"> {{ formatNumber(item.AUDTotalPay) }} {{ currency }} </template>
          <template v-slot:item.TaxTotalPay="{ item }"> {{ formatNumber(item.TaxTotalPay) }} {{ currency }} </template>
        </v-data-table>
      </v-card-text>
      <v-row>
        <v-col md="12" class="text-right">
          <v-btn color="error" @click="close"> <v-icon>mdi-close</v-icon> Cancel </v-btn>
        </v-col>
      </v-row>
      <v-dialog persistent v-model="showAddPaymentConfirmDialog" @keydown.esc="showAddPaymentConfirmDialog = false" max-width="400px">
        <confirm-dialog
          :loading="loading"
          @confirm="addPayment"
          @cancel="
            showAddPaymentConfirmDialog = false;
            loading = false;
          "
          :message="confirmModel"
        >
        </confirm-dialog>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import rules from "shared-components/src/utils/validations";
import { CustomerInvoiceApi, CustomerInvoicePaymentVM } from "shared-components/src/services/openApi";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";

var customerInvoiceApi = new CustomerInvoiceApi();

export default defineComponent({
  props: ["selectedInvoice"],
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      rules,
      paymentDatePickerMenu: false,
      InvoiceAUDTotalPay: this.selectedInvoice.audTotalAmount,
      InvoiceTaxTotalPay: this.selectedInvoice.audTaxAmount,
      InvoiceFromDate: this.selectedInvoice.fromDate,
      InvoiceToDate: this.selectedInvoice.toDate,
      InvoicePayments: this.selectedInvoice.payments,
      model: {
        PaymentDate: null as Date | null,
        PaymentRefNo: null as string | null,
        AUDPaymentAmount: 0,
        TaxPaymentAmount: 0,
      },
      loading: false,
      valid: true,
      confirmModel: {
        title: "Add new payment",
        text: "Are you sure?",
      },
      showAddPaymentConfirmDialog: false,
    };
  },
  methods: {
    getColumns() {
      return [
        { title: "Payment Date", value: "paymentDate", sortable: false },
        { title: "Payment Reference", value: "paymentRefNo", sortable: false },
        { title: `Payment Amount ${this.currency}`, value: "audTotalPay", sortable: false, align: "center" },
        { title: `Payment Tax ${this.currency}`, value: "taxTotalPay", sortable: false, align: "center" },
      ];
    },
    fillAUDAmount() {
      this.model.AUDPaymentAmount = this.InvoiceAUDTotalPay;
      this.model.TaxPaymentAmount = this.InvoiceTaxTotalPay;
    },
    close(): void {
      this.resetForm();
      this.$emit("close");
    },
    async confirmAddPayment() {
      const isValid = await (this.$refs.frmPayment as any).validate();
      if (this.model && isValid.valid) {
        this.showAddPaymentConfirmDialog = true;
      }
    },
    async addPayment() {
      const payment = {
        audTotalPay: this.model.AUDPaymentAmount,
        paymentDate: this.model.PaymentDate ? Utils.toVsDateFormat(this.model.PaymentDate) : "",
        paymentRefNo: this.model.PaymentRefNo,
        taxTotalPay: this.model.TaxPaymentAmount,
      } as CustomerInvoicePaymentVM;
      const isValid = await (this.$refs.frmPayment as any).validate();
      if (this.model && isValid.valid) {
        try {
          this.loading = true;
          await customerInvoiceApi.customerInvoiceAddPayment(this.selectedInvoice.id, payment);
        } finally {
          this.loading = false;
          this.resetForm();
        }
      }
    },
    resetForm() {
      this.showAddPaymentConfirmDialog = false;
      this.$emit("confirmed", this.selectedInvoice.id);
      if (this.$refs.frmPayment) {
        (this.$refs.frmPayment as any).reset();
      }
    },
    formatDate(date: Date): string {
      return Utils.toVsDateFormat(date);
    },
    formatNumber(value: number): string {
      return Utils.moneyFormat(value);
    },
  },
  computed: {
    currency(): string {
      return this.selectedInvoice?.currency ?? "";
    },
    formatedPaymentDate() {
      return this.model.PaymentDate ? Utils.toVsDateFormat(this.model.PaymentDate) : null;
    },
  },
});
</script>

<style scoped>
.ref-form {
  width: 100%;
}
</style>
