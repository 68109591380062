<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row no-gutters>
        <span>Loan List</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2" @click="showDialog = true"> <v-icon>mdi-plus</v-icon> Loan Request</v-btn>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col class="filters" md="4">
          <ComboBoxField
            :loading="tmLoading"
            v-model="selectedTeammember"
            :items="teammembers"
            :firstInactiveId="firstInactiveTeammemberId"
            item-title="FullName"
            item-value="Id"
            outlined
            dense
            label="Team Member"
            placeholder="Select Team Member"
          >
          </ComboBoxField>
        </v-col>
        <v-col class="filters" md="4">
          <ComboBoxField
            v-model="selectedStatus"
            :items="statuses"
            item-title="FullName"
            item-value="Id"
            outlined
            dense
            label="Status"
            placeholder="Select Status"
          >
          </ComboBoxField>
        </v-col>
        <v-col class="filters" md="2">
          <v-btn class="secondary mt-7" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-data-table-server
        :items="items"
        :headers="headers"
        :loading="loading"
        show-expand
        :page="page"
        :items-per-page="numberOfPages"
        @update:options="(event: any) => tableOptions = event"
        :items-length="totalItemsCount"
      >
        <template v-slot:item.CreatedAt="{ item }">
          <strong>{{ formatDate(item.CreatedDate) }}</strong>
        </template>
        <template v-slot:item.ProposedPaymentDate="{ item }">
          <strong>{{ formatDate(item.ProposedPaymentDate) }}</strong>
        </template>
        <template v-slot:item.TeamMember="{ item }">
          <span>{{ item.TeamMemberName }}</span>
        </template>
        <template v-slot:item.Amount="{ item }">
          <span>{{ formatNumber(item.Currency, item.Amount) }}</span>
        </template>
        <template v-slot:item.UnPaidAmount="{ item }">
          <span>{{ formatNumber(item.Currency, item.UnPaidAmount) }}</span>
        </template>
        <template v-slot:item.Installment="{ item }">
          <span>{{ item.Installment }} [{{ formatNumber(item.Currency, getInstallmentAmount(item)) }}]</span>
        </template>
        <template v-slot:item.PaymentReference="{ item }">
          <span>{{ item.PaymentReference }}</span>
        </template>
        <template v-slot:item.StartPaymentDate="{ item }">
          <span>{{ formatDate(item.StartPaymentDate) }}</span>
        </template>
        <template v-slot:item.Description="{ item }">
          <span>{{ item.Description + " [" + item.Reference + "]" }}</span>
        </template>
        <template v-slot:item.Status="{ item }" class="text-center">
          <v-chip v-if="item.Status == loanStatuses.Pending" class="ma-2" color="orange"> Pending </v-chip>
          <v-chip v-if="item.Status == loanStatuses.Approved" class="ma-2" color="success"> Approved </v-chip>
          <v-chip v-if="item.Status == loanStatuses.Rejected" class="ma-2" color="error"> Rejected </v-chip>
          <v-chip v-if="item.Status == loanStatuses.FullyPaid" class="ma-2" color="green"> Fully Re-Paid </v-chip>
        </template>
        <template v-slot:item.Actions="{ item }">
          <span class="operation text-center">
            <v-menu offset-y left attach v-if="canBeDeleted(item) || canBeApproved(item) || canBePaid(item)">
              <template v-slot:activator="{ props }">
                <v-btn x-small icon color="black" v-bind="props">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="canBeDeleted(item)" @click="confirmDelete(item)" prepend-icon="mdi-delete" title="Delete" />
                <v-list-item v-if="canBeApproved(item)" @click="edit(item)" prepend-icon="mdi-pencil" title="Approve / Reject" />
                <v-list-item v-if="canBePaid(item)" @click="enterPayment(item)" prepend-icon="mdi-pencil" title="Enter Payment" />
              </v-list>
            </v-menu>
          </span>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan="columns.length" class="custom-timesheet-row" v-if="item.PaymentOverview && item.PaymentOverview.length > 0">
            <v-row class="ma-0">
              <v-col md="2"> Amount </v-col>
              <v-col md="6"> Description </v-col>
              <v-col md="4"> Payment Date </v-col>
            </v-row>
            <div
              v-for="overviewItem in item.PaymentOverview"
              :key="overviewItem.Description"
              :class="[`timesheet-row`, overviewItem.IsPaid ? 'b-light-green text-black' : '']"
            >
              <v-row class="ma-0">
                <v-col md="2">
                  {{ formatNumber(item.Currency, overviewItem.Amount) }}
                </v-col>
                <v-col md="6">
                  {{ overviewItem.Description }}
                </v-col>
                <v-col md="4">
                  {{ formatDate(overviewItem.PaidDate) }}
                </v-col>
              </v-row>
            </div>
          </td>
        </template>
      </v-data-table-server>
      <v-alert v-if="totals && totals.length" type="info" class="text-right">
        <span v-for="totalItem in totals">
          <strong>Amount {{ totalItem.currency }}: </strong> {{ formatNumber(totalItem.currency ?? "", totalItem.totalAmount ?? 0) }} <br /><strong
            >Remaining Amount {{ totalItem.currency }}:
          </strong>
          {{ formatNumber(totalItem.currency ?? "", totalItem.totalRemainingAmount ?? 0) }}
          <br />
          <hr />
        </span>
      </v-alert>
    </v-card-text>
    <v-dialog width="660" v-model="showDialog" @keypress.esc="showDialog = false">
      <LoanRegister
        v-if="showDialog"
        :id="selectedId"
        :loading="loading"
        :teammembers="teammembers"
        @cancel="
          selectedId = null;
          showDialog = false;
        "
        @upsert="upsert"
      />
    </v-dialog>
    <v-dialog width="600" v-model="showStatusDialog" @keypress.esc="showStatusDialog = false">
      <LoanApproval
        v-if="showStatusDialog"
        :id="selectedId"
        :ProposedPaymentDate="selectedProposedStartDate"
        :value="selectedLoanApprovalModel"
        :loading="loading"
        @cancel="
          selectedId = null;
          showStatusDialog = false;
        "
        @approveLoan="approveLoan"
      />
    </v-dialog>
    <v-dialog width="600" v-model="showPaymentDialog" @keypress.esc="showPaymentDialog = false">
      <LoanPayment
        v-if="showPaymentDialog"
        :id="selectedId"
        :ProposedPaymentDate="selectedProposedStartDate"
        :value="selectedLoanApprovalModel"
        :loading="loading"
        @cancel="
          selectedId = null;
          showPaymentDialog = false;
        "
        @payLoan="payLoan"
      />
    </v-dialog>
    <v-dialog persistent v-model="showDeleteConfirmDialog" @keydown.esc="showDeleteConfirmDialog = false" max-width="400px">
      <confirm-dialog
        :loading="loading"
        @confirm="deleteItem"
        @cancel="
          showDeleteConfirmDialog = false;
          loading = false;
        "
        :message="confirmModel"
      >
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import LoanRegister from "./LoanRegister.vue";
import LoanApproval from "./LoanApproval.vue";
import LoanPayment from "./LoanPayment.vue";
import LoanService from "shared-components/src/services/LoanService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import { TeamMemberSort, TeamMemberSummarySort } from "shared-components/src/models/Teammember";
import Utils from "shared-components/src/utils/Utils";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { LoanRequest, LoanVM, LoanApprovalModel, LoanStatus } from "shared-components/src/models/Loan";
import AppHelper from "shared-components/src/utils/AppHelper";
import store from "@/store";
import { LoanApi, LoanModel, LoanRequestModel, LoanTotals, PaginationRequestModel } from "shared-components/src/services/openApi";

export default defineComponent({
  components: { LoanRegister, LoanApproval, LoanPayment, ConfirmDialog },
  async mounted() {
    this.fetchStatuses();
    await this.fetchTeammembers();
  },
  data() {
    return {
      tmLoading: true,
      loanStatuses: LoanStatus,
      itemRemoveId: -1,
      showDeleteConfirmDialog: false,
      teammembers: [] as TeamMemberSort[] | TeamMemberSummarySort[],
      statuses: [] as any[],
      selectedId: null as string | null,
      selectedTeammember: "",
      selectedStatus: "" as string | null | number,
      loading: false,
      showDialog: false,
      showStatusDialog: false,
      showPaymentDialog: false,
      selectedLoanApprovalModel: undefined as undefined | LoanApprovalModel,
      selectedProposedStartDate: new Date(),
      items: undefined as any | undefined,
      confirmModel: {
        title: "Remove Loan Item",
        text: "Are you sure?",
      },
      headers: [
        { title: "Request Date", sortable: true, align: "start", value: "CreatedAt" },
        { title: "Requested Start Date", sortable: true, align: "start", value: "ProposedPaymentDate" },
        { title: "Team Member", value: "TeamMember", sortable: false, align: "left" },
        { title: "Amount", sortable: true, value: "Amount", align: "right" },
        { title: "Remaining", sortable: false, value: "UnPaidAmount", align: "right" },
        { title: "Number Of Installments", sortable: false, value: "Installment", align: "center" },
        { title: "Payment Reference", sortable: false, value: "PaymentReference", align: "center" },
        { title: "Payment Date", sortable: true, value: "StartPaymentDate", align: "center" },
        { title: "Description", value: "Description", sortable: false },
        { title: "Status", sortable: false, value: "Status", align: "center" },
        { title: "Actions", sortable: false, value: "Actions", align: "center" },
      ],
      firstInactiveTeammemberId: "",
      page: 1,
      totalItemsCount: 0 as number | undefined,
      totals: [] as LoanTotals[],
      numberOfPages: 10,
      sortProp: "ProposedPaymentDate",
      sortOrder: "desc",
      tableOptions: {} as any,
    };
  },
  methods: {
    snackbarMessage(messageObject: any) {
      if (messageObject.type == "success") {
        store.dispatch("showSuccessMessage", messageObject.message);
      } else {
        store.dispatch("showErrorMessage", messageObject.message);
      }
    },
    canBeDeleted(item: any) {
      return LoanService.CanBeDeleted(item);
    },
    confirmDelete(item: any) {
      this.selectedId = item.id;
      this.showDeleteConfirmDialog = true;
    },
    async deleteItem() {
      try {
        this.loading = true;
        if (this.selectedId != null) {
          await LoanService.deleteByAdmin(this.selectedId);
          this.snackbarMessage({
            message: "Loan has been successfully deleted!",
            type: "success",
          });
          this.fetchLoans();
          this.showDeleteConfirmDialog = false;
        } else {
          this.snackbarMessage({
            message: "Loan has not been selected yet",
            type: "error",
          });
        }
      } finally {
        this.loading = false;
        this.showDeleteConfirmDialog = false;
      }
    },
    canBeApproved(row: any) {
      return (
        (row.Status == this.loanStatuses.Approved || row.Status == this.loanStatuses.Pending || row.Status == this.loanStatuses.Rejected) &&
        !row.StartPaymentDate
      );
    },
    canBePaid(row: any) {
      return row.Status == this.loanStatuses.Approved && !row.PaymentReference && !row.StartPaymentDate;
    },
    getInstallmentAmount(row: LoanVM) {
      return row.Amount / row.Installment;
    },
    clearFilters() {
      this.selectedTeammember = "";
      this.selectedStatus = "";
    },
    fetchStatuses() {
      this.statuses = [];
      LoanService.getLoanStatuses().forEach((item: any) => {
        const statusItem = { Id: item.status, FullName: item.value };
        this.statuses.push(statusItem);
      });
      this.statuses.splice(0, 0, { Id: "", FullName: "All" });
    },
    findFirstInactiveTeammember(teammemberSortedList: any) {
      var inactiveTeammember = teammemberSortedList.find((c: any) => c.isFirstInactive);
      if (inactiveTeammember) {
        this.firstInactiveTeammemberId = inactiveTeammember.Id;
      }
    },
    async fetchTeammembers() {
      if (AppHelper.IsAzureEnv()) {
        TeamMemberService.getSortedSummaryList()
          .then((items: any) => {
            this.findFirstInactiveTeammember(items);
            this.teammembers = items;
            this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSummarySort);
          })
          .finally(() => (this.tmLoading = false));
      } else {
        TeamMemberService.getSortedList()
          .then((items: any) => {
            this.findFirstInactiveTeammember(items);
            this.teammembers = items;
            this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSort);
          })
          .finally(() => (this.tmLoading = false));
      }
    },
    formatDate(date: Date): string {
      return `${Utils.toVsDateFormat(date)}`;
    },
    formatNumber(currency: string, value: number | undefined): string {
      return `${Utils.moneyFormat(value)} ${currency}`;
    },
    remove(index: any): void {
      this.itemRemoveId = index;
      this.showDeleteConfirmDialog = true;
    },
    edit(item: any): void {
      const loanApprovalModel = {
        IsRejected: item.Status == this.loanStatuses.Rejected,
        LoanId: item.id,
        PaymentReference: item.PaymentReference,
        RejectionReason: item.RejectReason,
        CommitmentId: item.CommitmentId,
        CommitmentName: item.CommitmentName,
        Currency: item.Currency,
        StartPaymentDate: Utils.vsDateToDatetime(item.StartPaymentDate),
      } as LoanApprovalModel;
      this.showStatusDialog = true;
      this.selectedProposedStartDate = item.ProposedPaymentDate;
      this.selectedLoanApprovalModel = loanApprovalModel;
    },
    enterPayment(item: any): void {
      const loanApprovalModel = {
        IsRejected: item.Status == this.loanStatuses.Rejected,
        LoanId: item.id,
        PaymentReference: item.PaymentReference,
        RejectionReason: item.RejectReason,
        StartPaymentDate: Utils.vsDateToDatetime(item.StartPaymentDate),
      } as LoanApprovalModel;
      this.showPaymentDialog = true;
      this.selectedProposedStartDate = item.ProposedPaymentDate;
      this.selectedLoanApprovalModel = loanApprovalModel;
    },
    async upsert(model: LoanModel): Promise<void> {
      try {
        this.loading = true;
        if (model.id) {
          await new LoanApi().updateLoan(model);
        } else {
          await new LoanApi().addLoanAdmin(model);
        }
        this.snackbarMessage({
          message: "Loan saved successfully",
          type: "success",
        });
      } finally {
        this.showDialog = false;
        this.selectedId = null;
        this.loading = false;
        this.fetchLoans();
      }
    },

    async approveLoan(model: LoanApprovalModel): Promise<void> {
      try {
        this.loading = true;
        if (model.StartPaymentDate) {
          model.StartPaymentDate = Utils.addTimezoneOffset(model.StartPaymentDate);
        }
        await LoanService.approval(model);
        this.snackbarMessage({
          message: "Loan updated successfully",
          type: "success",
        });
      } finally {
        this.showStatusDialog = false;
        this.selectedId = null;
        this.loading = false;
        this.fetchLoans();
      }
    },
    async payLoan(model: LoanApprovalModel): Promise<void> {
      try {
        this.loading = true;
        if (model.StartPaymentDate) {
          model.StartPaymentDate = Utils.addTimezoneOffset(model.StartPaymentDate);
        }
        await LoanService.payment(model);
        this.snackbarMessage({
          message: "Loan updated successfully",
          type: "success",
        });
      } finally {
        this.showPaymentDialog = false;
        this.selectedId = null;
        this.loading = false;
        this.fetchLoans();
      }
    },
    async fetchLoans() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;
        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
        } as PaginationRequestModel;
        const model = {
          TeamMemberId: this.selectedTeammember ? (this.selectedTeammember as any).Id : "",
          status: this.selectedStatus ? (this.selectedStatus as any).Id : "",
          paginationModel,
        } as LoanRequestModel;
        const result = (await new LoanApi().getAllLoans(model)).data;
        this.items = result.data;
        this.totalItemsCount = result.totalCount;
        this.totals = result.Totals ?? [];
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    selectedTeammember() {
      this.fetchLoans();
    },
    selectedStatus() {
      this.fetchLoans();
    },
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
          this.sortOrder = newVal.sortBy[0].order;
        }

        this.fetchLoans();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.custom-table-row {
  background-color: red;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
