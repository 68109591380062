<template>
    <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="applications"
    :items-length="totalItems"
    :loading="loading"
    :search="search"
    item-value="name"
    @update:options="fetchListOfCandidatesShortlisted"
  >
    <template v-slot:item.actions="{ item, index }">
      <v-btn v-if="!showComboBoxes[index]" @click="toggleComboBox(index)" class="text-uppercase" size="small" color="green" text="Change Status">
      </v-btn>
      <v-row v-if="showComboBoxes[index]">
        <v-col cols="8">
        <ComboBoxField
          v-model="item.Status"
          :items="statuses"
          clearable
          dense
          hide-details/>
        </v-col>
        <v-col cols="4">
          <v-btn class="ma-auto" @click="changePositionApplicationStatus(item, index)" color="green">Done</v-btn>
        </v-col>
      </v-row>
    </template >
    <template v-slot:item.audit="{ item, index }">
      <v-btn class="ma-auto" @click="fetchAudits(item)" color="green">More Info</v-btn>
    </template>
  </v-data-table-server>
  <v-dialog v-model="showMoreInfo" scrollable class="w-50">
    <template v-slot:default="{ isActive }">
        <v-card title="Application Audits">
          <v-divider class="mt-3"></v-divider>
          <v-card-text class="px-4" style="height: 300px;">
            <v-list-item 
              v-for="item in auditsForApplication"
              :subtitle="dateToString(item.DateTime).toString()"
              :title="`${item.Event} - ${item.ipDetail?.detail?.location?.city} - ${item.ipDetail?.detail?.location?.country?.name}`">
            <template v-slot:prepend>
              <v-icon class="bg-primary">mdi-account</v-icon>
            </template>
          </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text="Close"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
</template>
<script lang="ts">
import {
  BaseRequestModel,
  PositionApi,
  PositionApplicationWithTeammemberSummaryInfoResponseModel as PositionApplicationInfo,
  PositionApplicationRequestModel, PositionApplicationRequestModelStatusEnum
} from "shared-components/src/services/openApi/api";
import { defineComponent } from "vue";
import ComboBoxField from "shared-components/src/components/Fields/ComboBoxField.vue";
import store from "@/store";
import moment from "moment";
import { AuditTrailApi, AuditTrailFullResponseModel, AuditTrailRequestModel } from "shared-components/src/services/openApi";

export default defineComponent({
  props: {
    positionId: {
      type: String,
      required: true,
    },
  },
  components: { ComboBoxField, },
  data() {
    return {
      itemsPerPage: 10,
      showMoreInfo: false,
      headers: [
        { title: "Teammember Email", align: "start", sortable: false, key: "TeammemberInfo.Email",},
        { title: "Application Date", key: "ApplicationDate" },
        { title: "Source", key: "Source" },
        { title: "Status", key: "Status" },
        { title: "Scores", sortable: false, key: "Scores" },
        { title: "Actions", sortable: false, key: "actions", },
        { title: "Audit", sortable: false, key: "audit"}
      ],
      search: "",
      applications: [] as PositionApplicationInfo[],
      auditsForApplication: [] as AuditTrailFullResponseModel[],
      loading: true,
      totalItems: 0,
      showComboBoxes: [] as boolean[],
      selectedStatus: "" as string | null,
      statuses: Object.values(PositionApplicationRequestModelStatusEnum) as string[],
    };
  },
  async mounted() {
    await this.fetchListOfCandidatesShortlisted({});
  },
  methods: {
    async fetchAudits(item: PositionApplicationInfo) {
      var request = {
        Events: ["Login", "ApplyForPosition"],
        RecordTypeIds: [item.Id],
        UserIds: [item.TeammemberInfo?.UserAccountsId],
      } as AuditTrailRequestModel;
      this.auditsForApplication = (await new AuditTrailApi().getAuditTrailListWithIPs(request)).data as AuditTrailFullResponseModel[];
      this.showMoreInfo = true;
    },
    dateToString(date: object | undefined) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:MM");
      }
      return "";
    },
    async changePositionApplicationStatus(position: PositionApplicationInfo, index: number) {
      this.showComboBoxes[index] = false;
      var request = {
        "Status": position.Status,
        "PositionId": this.positionId
      } as PositionApplicationRequestModel;
      try {
        await new PositionApi().updatePositionApplication(request);
        store.dispatch("showSuccessMessage", "Position's Application Updated Successfully.");
      } catch (error) {
        store.dispatch("showErrorMessage", "Failed to Update Position's Application.");
      }
    },
    toggleComboBox(index: number) {
      this.showComboBoxes[index] = true;
    },
    async fetchListOfCandidatesShortlisted({ page, itemsPerPage, sortBy }: any) {
      this.loading = true;
      var request = {fields: {"PositionId": this.positionId}, sortProps: sortBy ?? [{"key": "ApplicationDate", "order": "desc"}]} as BaseRequestModel;
      this.applications = (await new PositionApi().getPositionApplicationForAdmin(request)).data;
      this.totalItems = this.applications.length;
      this.showComboBoxes = new Array(this.totalItems).fill(false);
      this.loading = false;
    },
  },
});
</script>