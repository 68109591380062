import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.applications,
      "items-length": _ctx.totalItems,
      loading: _ctx.loading,
      search: _ctx.search,
      "item-value": "name",
      "onUpdate:options": _ctx.fetchListOfCandidatesShortlisted
    }, {
      "item.actions": _withCtx(({ item, index }) => [
        (!_ctx.showComboBoxes[index])
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              onClick: ($event: any) => (_ctx.toggleComboBox(index)),
              class: "text-uppercase",
              size: "small",
              color: "green",
              text: "Change Status"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showComboBoxes[index])
          ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "8" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ComboBoxField, {
                      modelValue: item.Status,
                      "onUpdate:modelValue": ($event: any) => ((item.Status) = $event),
                      items: _ctx.statuses,
                      clearable: "",
                      dense: "",
                      "hide-details": ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "items"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "ma-auto",
                      onClick: ($event: any) => (_ctx.changePositionApplicationStatus(item, index)),
                      color: "green"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Done")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      "item.audit": _withCtx(({ item, index }) => [
        _createVNode(_component_v_btn, {
          class: "ma-auto",
          onClick: ($event: any) => (_ctx.fetchAudits(item)),
          color: "green"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("More Info")
          ])),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["items-per-page", "headers", "items", "items-length", "loading", "search", "onUpdate:options"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showMoreInfo,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMoreInfo) = $event)),
      scrollable: "",
      class: "w-50"
    }, {
      default: _withCtx(({ isActive }) => [
        _createVNode(_component_v_card, { title: "Application Audits" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_divider, { class: "mt-3" }),
            _createVNode(_component_v_card_text, {
              class: "px-4",
              style: {"height":"300px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.auditsForApplication, (item) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    subtitle: _ctx.dateToString(item.DateTime).toString(),
                    title: `${item.Event} - ${item.ipDetail?.detail?.location?.city} - ${item.ipDetail?.detail?.location?.country?.name}`
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "bg-primary" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("mdi-account")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["subtitle", "title"]))
                }), 256))
              ]),
              _: 1
            }),
            _createVNode(_component_v_divider),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  text: "Close",
                  onClick: ($event: any) => (isActive.value = false)
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}