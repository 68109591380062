<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title> Loan Request For Team Member </v-card-title>
    <v-card-text>
      <v-form ref="frmRegister" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="filters" md="6">
            <ComboBoxField
              :rules="[rules.required]"
              v-model="selectedTeammember"
              :items="teammemberList"
              item-title="FullName"
              item-value="Id"
              outlined
              dense
              label="Team Member"
            >
              <template v-slot:item="{ item, index, props }">
                <v-list>
                  <v-list-item v-bind="props" :class="{ 'txt-color': item.raw.isFirstInactive }" :title="item.raw.FullName"></v-list-item>
                </v-list>
              </template>
            </ComboBoxField>
          </v-col>
          <v-col md="6">
            <AutoCompleteField
              :rules="[rules.required]"
              class="action-field"
              :items="commitments"
              item-title="text"
              item-value="id"
              v-model="model.CommitmentId"
              label="Commitment"
              placeholder="Commitment"
              outlined
            />
          </v-col>
          <v-col md="6">
            <CurrencyTextFieldV2
              :rules="[rules.required, rules.currency]"
              dense
              outlined
              label="Amount"
              v-model="model.Amount"
              :readonly="isReadOnly"
            />
          </v-col>
          <v-col md="6">
            <TextField
              dense
              :rules="[rules.required, rules.int, isValidInstallments]"
              outlined
              label="Number Of Installments"
              v-model="model.Installment"
            ></TextField>
          </v-col>
          <v-col md="6">
            <DateInputField
              first-day-of-week="1"
              v-model="model.ProposedPaymentDate"
              label="Requested Start Date"
              @update:modelValue="startDatePickerMenu = false"
              hide-actions
              placeholder="Select Date"
              prepend-icon=""
            ></DateInputField>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <TextAreaField v-model="model.Description" outlined label="Description"></TextAreaField>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn class="secondary_btn mr-5" @click="cancel"> cancel</v-btn>
      <v-btn class="primary_btn" @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import { LoanStatus, LoanVM } from "shared-components/src/models/Loan";
import { Teammember } from "shared-components/src/models/Teammember";
import rules from "shared-components/src/utils/validations";
import { CommitmentApi, CommitmentBillableItemMinModel, LoanModel } from "shared-components/src/services/openApi";

export default defineComponent({
  props: ["id", "teammembers", "loading"],
  data() {
    return {
      startDatePickerMenu: false,
      valid: true,
      teammemberList: this.teammembers.filter((x: any) => x.FullName != "All"),
      selectedTeammember: undefined as Teammember | undefined,
      startMenu: false,
      endMenu: false,
      commitmentBillableItemsMin: [] as CommitmentBillableItemMinModel[],
      commitments: [] as Array<{
        id: string;
        text: string;
      }>,
      model: {
        id: "",
        Amount: 0,
        Installment: 0,
        Description: "",
        TeamMemberId: "",
        TeamMemberName: "",
        ProposedPaymentDate: new Date(),
      } as LoanModel,
      rules,
    };
  },
  methods: {
    resetModel(): void {
      this.model = {
        id: "",
        Amount: 0,
        Installment: 0,
        Description: "",
        TeamMemberId: "",
        TeamMemberName: "",
        ProposedPaymentDate: new Date(),
        CommitmentId: "",
        Currency: "",
        Status: LoanStatus.Pending,
      };
    },
    async fetchCommitments() {
      try {
        const tmId = this.selectedTeammember?.Id ?? "";
        if (tmId) {
          this.commitmentBillableItemsMin = (await new CommitmentApi().getCommitmentsMinContainSalaryByTeammeberId(tmId)).data;
          this.commitments = this.commitmentBillableItemsMin.map((item) => {
            return { id: item.commitmentId ?? "", text: item.commitmentFullName ?? "" };
          });
        }
      } finally {
      }
    },
    formatNumber(value: number): string {
      return Utils.moneyFormat(value);
    },
    setAmount($event: any): void {
      this.model.Amount = Utils.currencyToNumber($event);
    },
    cancel(): void {
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      this.model.ProposedPaymentDate = Utils.getDateString(this.model.ProposedPaymentDate as any, true) as any;

      const isValid = await (this.$refs.frmRegister as any).validate();
      if (isValid.valid && this.selectedTeammember) {
        this.model.TeamMemberId = this.selectedTeammember.Id;
        this.$emit("upsert", this.model);
      }
    },
  },
  watch: {
    "model.CommitmentId"(newValue) {
      this.model.Currency = this.commitmentBillableItemsMin.find((x) => x.commitmentId == newValue)?.currency;
    },
    selectedTeammember(newValue) {
      this.model.CommitmentId = "";
      this.fetchCommitments();
    },
  },
  computed: {
    installmentAmountPerMonth(): number {
      if (this.model.Installment) {
        const instPerMonth = (parseInt((this.model.Amount ?? 0).toString()) / this.model.Installment).toString();
        return parseFloat(Number.parseFloat(instPerMonth).toFixed(2));
      }
      return 0;
    },
    isValidInstallments(): boolean {
      const installment = this.model.Installment ? parseInt((this.model.Installment ?? 0).toString()) : 0;

      if (installment) {
        return this.installmentAmountPerMonth * installment === this.model.Amount;
      }
      return false;
    },
    isValidTeamMember(): boolean {
      if (this.model.TeamMemberId) {
        return true;
      }
      return false;
    },
    minDate(): string {
      const dateLimit = new Date();
      dateLimit.setDate(dateLimit.getDate());
      dateLimit.setHours(0);
      dateLimit.setMinutes(0);
      dateLimit.setSeconds(0);
      dateLimit.setMilliseconds(0);
      return Utils.toVsDateFormat(dateLimit);
    },
  },
});
</script>
