import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "pb-0" }
const _hoisted_4 = { class: "tinfo teammemberText" }
const _hoisted_5 = { class: "tinfo ml-2 mr-1" }
const _hoisted_6 = { class: "tinfo teammemberText" }
const _hoisted_7 = { class: "tinfo ml-2 mr-1" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "rectCard addProjectBut" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_customer_detail = _resolveComponent("customer-detail")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_project_detail = _resolveComponent("project-detail")!
  const _component_EpicList = _resolveComponent("EpicList")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(" Loading customers, please wait... ")
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer) => {
            return (_openBlock(), _createBlock(_component_v_row, {
              key: customer.id,
              onMouseover: ($event: any) => (_ctx.customerHovered(customer.id)),
              align: "center",
              class: "flowContainer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  md: "3",
                  sm: "6",
                  class: _normalizeClass(_ctx.getCustomerProjects(customer.id).length ? 'customer' : '')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "rectCard",
                      onClick: ($event: any) => (_ctx.customerDetail(customer))
                    }, _toDisplayString(`${customer.FirstName} ${customer.LastName}`), 9, _hoisted_2)
                  ]),
                  _: 2
                }, 1032, ["class"]),
                _createVNode(_component_v_col, {
                  dense: "",
                  md: "9",
                  sm: "6",
                  class: _normalizeClass(_ctx.getCustomerProjects(customer.id).length ? 'project' : '')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCustomerProjects(customer.id), (project) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: project.id,
                        class: "projectName"
                      }, [
                        _createVNode(_component_v_row, {
                          class: "rectCard",
                          onClick: ($event: any) => (_ctx.projectDetail(project))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, { "no-gutters": "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "10" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h2", _hoisted_3, _toDisplayString(project.Name), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, {
                                      cols: "2",
                                      class: "text-right"
                                    }, {
                                      default: _withCtx(() => [
                                        _withDirectives(_createVNode(_component_v_btn, {
                                          variant: "text",
                                          size: "small",
                                          icon: "mdi-database-plus ",
                                          onClick: _withModifiers(($event: any) => (_ctx.showEpicsMenu(project)), ["stop"])
                                        }, null, 8, ["onClick"]), [
                                          [_directive_tooltip, 'Epic List']
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            (_ctx.getCommitmentLeads(project) && _ctx.getCommitmentLeads(project).length > 0)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  class: "pt-0 pl-0 pb-0"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "pt-0 pl-0 pb-0" }, {
                                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                                        _createElementVNode("span", { class: "projectDetailTitle" }, " Leads: ", -1)
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, { class: "pt-4 pl-3" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { class: "pt-0 pl-0 pb-0" }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCommitmentLeads(project), (l) => {
                                              return (_openBlock(), _createElementBlock("span", {
                                                class: "tmName tinfo",
                                                key: l.Name
                                              }, [
                                                _createElementVNode("span", _hoisted_4, _toDisplayString(l.Name), 1),
                                                _createElementVNode("span", _hoisted_5, [
                                                  _withDirectives(_createVNode(_component_v_img, {
                                                    class: "tmImage",
                                                    src: l.Photo | _ctx.profileImage,
                                                    "aspect-ratio": "1"
                                                  }, null, 8, ["src"]), [
                                                    [_directive_ripple]
                                                  ])
                                                ])
                                              ]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true),
                            (_ctx.getCommitmentTeammembers(project) && _ctx.getCommitmentTeammembers(project).length > 0)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 1,
                                  class: "teamMemberList pt-0 pl-0 pb-0"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { class: "pt-2 pl-0 pb-0" }, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createElementVNode("span", { class: "projectDetailTitle" }, " Team members: ", -1)
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, { class: "pt-4 pl-3" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { class: "pt-0 pl-0 pb-0" }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCommitmentTeammembers(project), (t) => {
                                              return (_openBlock(), _createElementBlock("span", {
                                                class: "tmName tinfo",
                                                key: t.Name
                                              }, [
                                                _createElementVNode("span", _hoisted_6, _toDisplayString(t.Name), 1),
                                                _createElementVNode("span", _hoisted_7, [
                                                  _withDirectives(_createVNode(_component_v_img, {
                                                    class: "tmImage",
                                                    style: _normalizeStyle({ 'border-color': _ctx.statusColor(t.Status) }),
                                                    src: t.Photo | _ctx.profileImage,
                                                    "aspect-ratio": "1"
                                                  }, null, 8, ["style", "src"]), [
                                                    [_directive_ripple]
                                                  ])
                                                ])
                                              ]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    }), 128)),
                    (_ctx.hoveredCustomerId === customer.id)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "projectName",
                          id: customer.id,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.projectDetail()))
                        }, [
                          _createElementVNode("span", _hoisted_9, [
                            _cache[12] || (_cache[12] = _createTextVNode(" New Project ")),
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("mdi-plus")
                              ])),
                              _: 1
                            })
                          ])
                        ], 8, _hoisted_8))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class"])
              ]),
              _: 2
            }, 1032, ["onMouseover"]))
          }), 128))
        ])),
    (_ctx.showCustomerDetail)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 2,
          modelValue: _ctx.showCustomerDetail,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showCustomerDetail) = $event)),
          fullscreen: "",
          onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.showCustomerDetail = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_customer_detail, {
              showClose: true,
              customer: _ctx.selectedCustomer,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCustomerDetail = false))
            }, null, 8, ["customer"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.showProjectDetail)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 3,
          modelValue: _ctx.showProjectDetail,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showProjectDetail) = $event)),
          onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showProjectDetail = false), ["esc"])),
          "max-width": "700"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_project_detail, {
              commitments: _ctx.projectCommitments,
              teammembers: _ctx.projectTeammembers,
              repos: _ctx.projectRepositories,
              users: _ctx.users,
              project: _ctx.selectedProject,
              customerId: _ctx.hoveredCustomerId,
              onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showProjectDetail = false)),
              onSavedProject: _ctx.savedProject
            }, null, 8, ["commitments", "teammembers", "repos", "users", "project", "customerId", "onSavedProject"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showEpicList,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showEpicList) = $event)),
      "max-width": "700"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EpicList, {
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showEpicList = false)),
          project: _ctx.selectedProject
        }, null, 8, ["project"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}