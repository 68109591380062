<template>
  <v-list nav class="pt-0 main-menu-items">
    <v-list-group v-if="isAuthenticated && !isHiringmanager && !isSupport" v-model="managementDefaultOpen" class="m">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-briefcase-outline" title="Management" />
      </template>
      <v-list-item to="/billableItem/list" prepend-icon="mdi-package" title="Billable Items" />
      <v-list-item to="/timesheet/list" prepend-icon="mdi-history" title="Timesheet" />
      <v-list-item to="/commitment/list" prepend-icon="mdi-handshake" title="Commitments" />
      <v-list-item to="/commitment/listV2" prepend-icon="mdi-handshake" title="Commitments V2" />
      <v-list-item to="/payRuns" prepend-icon="mdi-account-cash" title="Pay Run" />
      <v-list-item to="/payRunsV2" prepend-icon="mdi-account-cash" title="Pay Run V2" />
      <v-list-item to="/loans" prepend-icon="mdi-cash-multiple" title="Loans" />
      <v-list-item to="/notificationManagement" prepend-icon="mdi-bell" title="Notification Management" />
      <v-list-item to="/weeklyTimesheetAudit" prepend-icon="mdi-server-security" title="Weekly Timesheet Audit" />
      <v-list-item to="/customers/chart" prepend-icon="mdi-file-tree" title="Customers Chart" />
      <v-list-item to="/auditTrail" prepend-icon="mdi-eye-outline" title="Audit Trail" />
      <v-list-item to="/publicHolidays" prepend-icon="mdi-calendar-multiple-check" title="Public Holidays" />
      <v-list-item to="/repositories" prepend-icon="mdi-source-repository" title="Repositories" />
      <v-list-item to="/taskTemplate" prepend-icon="mdi-calendar-check" title="Task Template List" />
      <v-list-item to="/bulkImportExport" prepend-icon="mdi-swap-horizontal-bold" title="Bulk Import Export" />
      <v-list-item to="/dataManagement" prepend-icon="mdi-wrench-cog" title="Manage Data" />
    </v-list-group>
    <v-list-group v-if="isAuthenticated && !isHiringmanager && !isSupport" v-model="reportsDefaultOpen" class="r">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-chart-line" title="Reports" />
      </template>
      <v-list-item to="/reports/accounting/trialBalance" prepend-icon="mdi-cash" title="Trial Balance Report" />
      <v-list-item to="/reports/timesheet-report" prepend-icon="mdi-server-security" title="Timesheets Report" />
      <v-list-item to="/reports/revenue" prepend-icon="mdi-server-security" title="Revenue Report" />
      <v-list-item to="/reports/pay-roll" prepend-icon="mdi-server-security" title="PayRoll Report" />
      <v-list-item to="/reports/pay-roll-v2" prepend-icon="mdi-server-security" title="PayRollV2 Report" />
      <v-list-item to="/reports/analytical-report" prepend-icon="mdi-chart-areaspline" title="Analytics Report" />
      <v-list-item to="/reports/contribution-report" prepend-icon="mdi-google-analytics" title="Contribution Report" />
      <v-list-item to="/reports/hrcommitments-report" prepend-icon="mdi-google-analytics" title="HR Commitments Report" />
      <v-list-item to="/reports/expenseAnalysis" prepend-icon="mdi-google-analytics" title="Expense Analysis" />
      <v-list-item to="/reports/costAnalysis" prepend-icon="mdi-cash-register " title="Cost Analysis" />
    </v-list-group>
    <v-list-group v-if="isAuthenticated && !isHiringmanager && !isSupport" v-model="userManagementDefaultOpen" class="u">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-account-group-outline" title="User Management" />
      </template>
      <v-list-item to="/users/list" prepend-icon="mdi-account-group" title="Users List" />

      <v-list-item to="/activities/list" prepend-icon="mdi-ticket" title="Activities" />
      <v-list-item to="/access/list" prepend-icon="mdi-server-security" title="Accesses" />
    </v-list-group>
    <v-list-group v-if="(isAuthenticated || isHiringmanager) && !isSupport" v-model="recruitmentDefaultOpen" class="r">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-account-search" title="Recruitment" />
      </template>
      <v-list-item to="/job" prepend-icon="mdi-account-plus" title="Job opening" />
      <v-list-item to="/search" prepend-icon="mdi-account-multiple" title="Team Members" />
      <v-list-item to="/task" prepend-icon="mdi-calendar-check" title="My Tasks" />
      <v-list-item prepend-icon="mdi-inbox" title="Inbox" />
    </v-list-group>
    <v-list-group v-if="isAuthenticated && !isHiringmanager && !isSupport" v-model="teamRolesDefaultOpen" class="t">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-account-tie-outline" title="Team Roles" />
      </template>
      <v-list-item to="/customers" prepend-icon="mdi-voice" title="Customers" />
      <v-list-item to="/projectLeads" prepend-icon="mdi-voice" title="Project Leads" />
      <v-list-item to="/portfolioManagers" prepend-icon="mdi-voice" title="Portfolio Managers" />
      <v-list-item to="/clients" prepend-icon="mdi-voice" title="Clients" />
      <v-list-item to="/tdms" prepend-icon="mdi-voice" title="TDMs" />
      <v-list-item to="/lineManagers" prepend-icon="mdi-account-star" title="Line Managers" />
    </v-list-group>
    <v-list-group v-if="isAuthenticated && !isHiringmanager" v-model="networkDefaultOpen" class="n">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-network-outline" title="Network" />
      </template>
      <v-list-item to="/vm" prepend-icon="mdi-server" title="VM" />
      <v-list-item to="/vm/support" prepend-icon="mdi-face-agent" title="Support" />
    </v-list-group>
    <v-divider />
    <v-list-item v-if="!isAuthenticated" to="/login" prepend-icon="mdi-login" title="Login" />
    <v-list-item v-if="isAuthenticated" to="/logout" prepend-icon="mdi-logout" title="Logout" />
    <v-list-item v-if="isAuthenticated" to="/resetPassword" prepend-icon="mdi-update" title="Reset Password" />
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
      default: false,
    },
    isHiringmanager: {
      type: Boolean,
      required: true,
      default: false,
    },
    isSupport: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      managementDefaultOpen: true,
      reportsDefaultOpen: true,
      userManagementDefaultOpen: true,
      teamRolesDefaultOpen: true,
      recruitmentDefaultOpen: true,
      networkDefaultOpen: true,
    };
  },
  mounted() {
    let activeClass = "v-list-item--active";
    const managementGroupItems = document.querySelectorAll(".m a");
    this.managementDefaultOpen = Array.from(managementGroupItems).some((item) => item.classList.contains(activeClass));

    const reportsGroupItems = document.querySelectorAll(".r a");
    this.reportsDefaultOpen = Array.from(reportsGroupItems).some((item) => item.classList.contains(activeClass));

    const userManagementGroupItems = document.querySelectorAll(".u a");
    this.userManagementDefaultOpen = Array.from(userManagementGroupItems).some((item) => item.classList.contains(activeClass));

    const teamRolesGroupItems = document.querySelectorAll(".t a");
    this.teamRolesDefaultOpen = Array.from(teamRolesGroupItems).some((item) => item.classList.contains(activeClass));

    const recruitmentGroupItems = document.querySelectorAll(".r a");
    this.recruitmentDefaultOpen = Array.from(recruitmentGroupItems).some((item) => item.classList.contains(activeClass));
    const networkGroupItems = document.querySelectorAll(".n a");
    this.networkDefaultOpen = Array.from(networkGroupItems).some((item) => item.classList.contains(activeClass));
  },
});
</script>

<style scoped>
.main-menu-items {
  background: none;
}

.v-list-group {
  font-size: 15px;
}
.v-list-group__items .v-list-item__action .v-icon,
.v-list-group__items .v-list-item__title {
  font-size: 14px;
  padding-left: 10px;
}

.v-list-group__header .v-list-item {
  top: 5px;
}
.v-list-group__items .v-list-item--active .v-list-item__action,
.v-list-group__items .v-list-item--active .v-list-item__title,
.v-list-group__header.v-list-item--active i,
.v-list-group__header.v-list-item--active .v-list-item {
  color: black;
  font-weight: bolder;
}

.v-list-group__items .v-list-item__action .v-icon {
  right: -35px;
  top: -0.5px;
}
</style>
