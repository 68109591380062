<template>
  <v-card class="mx-auto">
    <v-card-title class="text-h5"> Invoice {{ invoice.invoiceNumber }} </v-card-title>

    <v-card-text>
      <v-row> </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <img :src="logoUrl" style="max-width: 45%" />
        </v-col>
        <v-col cols="12" md="6">
          <p class="mb-2">
            <strong>Bill To:</strong>
          </p>
          <p>{{ invoice.customer.CuFullName }} for {{ formatDate(invoice.fromDate) }} to {{ formatDate(invoice.toDate) }}</p>
          <p class="mb-2"><strong>Invoice Number:</strong> {{ invoice.invoiceNumber }}</p>
          <p class="mb-2"><strong>Due Date:</strong> {{ formatDate(invoice.dueDate) }}</p>
        </v-col>
      </v-row>

      <v-data-table :headers="headers" :items="itemsWithTotal" item-key="chargeId" :items-per-page="-1" hide-default-footer class="mt-7 mb-10">
        <template v-slot:item.title="{ item }">
          <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.title }}</span>
        </template>
        <template v-slot:item.quantity="{ item }">
          <span :class="{ 'font-weight-bold': item.isTotal }">{{ item.isTotal ? "" : item.quantity }}</span>
        </template>
        <template v-slot:item.fee="{ item }">
          <span :class="{ 'font-weight-bold': item.isTotal }">
            {{ item.isTotal ? "" : `${GetFormatTwoDecimal(item.fee)} ${invoice.currency}` }}
          </span>
        </template>
        <template v-slot:item.totalWitNotTaxAmount="{ item }">
          <span :class="{ 'font-weight-bold': item.isTotal }"> {{ GetFormatTwoDecimal(item.totalWitNotTaxAmount) }} {{ invoice.currency }} </span>
        </template>
        <template v-slot:item.taxAmount="{ item }">
          <span :class="{ 'font-weight-bold': item.isTotal }"> {{ GetFormatTwoDecimal(item.taxAmount) }} {{ invoice.currency }} </span>
        </template>

        <template v-slot:item.total="{ item }">
          <span :class="{ 'font-weight-bold': item.isTotal }"> {{ GetFormatTwoDecimal(item.total) }} {{ invoice.currency }} </span>
        </template>
      </v-data-table>
      <v-row>
        <v-col md="6">
          <v-btn v-if="!invoice.id" color="primary" @click="saveInvoice" :loading="loading"> <v-icon>mdi-check</v-icon> Save </v-btn> &nbsp;
          <v-btn color="error" @click="close"> <v-icon>mdi-close</v-icon> Close </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import logo from "shared-components/src/assets/images/code-clan-red.png"; // Adjust the path as needed
import { CustomerInvoiceApi, CustomerInvoiceItemVM, CustomerInvoiceVM } from "shared-components/src/services/openApi";
var customerInvoiceApi = new CustomerInvoiceApi();

export default defineComponent({
  name: "InvoiceComponent",
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logoUrl: logo,
      loading: false,
      headers: [
        { title: "Description", value: "title", width: "40%", sortable: false },
        { title: "Quantity", value: "quantity", width: "8%", align: "center", sortable: false },
        { title: "Unit Price", value: "fee", width: "12%", align: "end", sortable: false },
        { title: "Amount", value: "totalWitNotTaxAmount", width: "11%", align: "end", sortable: false },
        { title: "GST", value: "taxAmount", width: "12%", align: "end", sortable: false },
        { title: "Total", value: "total", width: "17%", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    itemsWithTotal() {
      if (!this.invoice.items) {
        return [
          {
            title: "Total",
            quantity: 0,
            fee: 0,
            total: 0,
            isTotal: true,
          },
        ];
      }
      const total = this.invoice.items.reduce((sum: number, item: CustomerInvoiceItemVM) => sum + (item && item.total ? item.total : 0), 0);
      const totalTax = this.invoice.items.reduce(
        (sum: number, item: CustomerInvoiceItemVM) => sum + (item && item.taxAmount ? item.taxAmount : 0),
        0
      );
      const totalAmount = this.invoice.items.reduce(
        (sum: number, item: CustomerInvoiceItemVM) => sum + (item && item.totalWitNotTaxAmount ? item.totalWitNotTaxAmount : 0),
        0
      );
      return [
        ...this.invoice.items,
        {
          title: "Total",
          quantity: 0,
          fee: 0,
          totalWitNotTaxAmount: totalAmount,
          taxAmount: totalTax,
          total: total,
          isTotal: true,
        },
      ];
    },
  },
  methods: {
    async saveInvoice() {
      this.loading = true;
      if (this.invoice as CustomerInvoiceVM) {
        customerInvoiceApi.createCustomerInvoice(this.invoice as CustomerInvoiceVM).finally(() => {
          this.loading = false; // Set loading to false after the operation is complete
          this.$emit("confirmed");
        });
      } else {
        this.$emit("confirmed");
      }
    },
    close(): void {
      this.$emit("close");
    },
    GetFormatTwoDecimal(number: number) {
      return Utils.moneyFormat(number, 2);
    },
    formatDate(date: string) {
      return Utils.toVsDateFormat(date);
    },
  },
});
</script>
