import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_pay_run_form = _resolveComponent("pay-run-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_PayRunDetail = _resolveComponent("PayRunDetail")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_set_refrence = _resolveComponent("set-refrence")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      class: "overflow-visible",
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _cache[10] || (_cache[10] = _createTextVNode(" PayRoll ")),
            _createVNode(_component_v_divider, {
              class: "mx-4",
              inset: "",
              vertical: ""
            }),
            _createVNode(_component_v_spacer)
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "filters ml-2",
              md: "3"
            }, {
              default: _withCtx(() => [
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "input-label" }, "Date", -1)),
                _createVNode(_component_VueDatePicker, {
                  modelValue: _ctx.selectedMonth,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMonth) = $event)),
                  "month-picker": "",
                  "auto-apply": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "filters",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ComboBoxField, {
                  loading: _ctx.tmLoading,
                  modelValue: _ctx.model.selectedTeammember,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.selectedTeammember) = $event)),
                  items: _ctx.teammembers,
                  firstInactiveId: _ctx.firstInactiveTeammemberId,
                  "item-title": "FullName",
                  "item-value": "Id",
                  outlined: "",
                  dense: "",
                  label: "Team Member"
                }, null, 8, ["loading", "modelValue", "items", "firstInactiveId"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "filters mt-10",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("mdi-sync")
                      ])),
                      _: 1
                    }),
                    _cache[13] || (_cache[13] = _createTextVNode(" Process "))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  color: "secondary",
                  class: "ml-2",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearFilters()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    }),
                    _cache[15] || (_cache[15] = _createTextVNode(" Clear "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: _ctx.items,
              headers: _ctx.headers,
              loading: _ctx.loading,
              "custom-sort": _ctx.customSort,
              "items-per-page": -1,
              "hide-default-footer": ""
            }, {
              "item.Index": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_1))
                  : (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(item.Index), 1))
              ]),
              "item.teamMemberName": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_3, "Total Payments"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.teamMemberName), 1))
              ]),
              "item.fromDate": _withCtx(({ item }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getMonthName(item)), 1)
              ]),
              "item.amount": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", _hoisted_5, _toDisplayString(_ctx.getTotalAudPaymentOfReport(_ctx.items)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.moneyFormatItem(item)), 1))
              ]),
              "item.paymentMethodType": _withCtx(({ item }) => [
                (item.paymentMethodType > 0)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      color: "primary",
                      small: "",
                      onClick: ($event: any) => (_ctx.showPaymentMethodDetailPopup(item))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getPaymentMethodNameByNumber(item.paymentMethodType)), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              "item.lcAmount": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", {
                      key: 0,
                      innerHTML: _ctx.getTotalLCPaymentOfReport(_ctx.items)
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.exChangeFormatItem(item)), 1))
              ]),
              "item.Action": _withCtx(({ item }) => [
                (item.isSum)
                  ? (_openBlock(), _createElementBlock("strong", {
                      key: 0,
                      innerHTML: _ctx.getTotalPaymentsOfReports(_ctx.items)
                    }, null, 8, _hoisted_9))
                  : (item.payRun)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                        _createVNode(_component_v_icon, {
                          color: "orange",
                          onClick: ($event: any) => (_ctx.setReferece(item))
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode("mdi-information")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createElementVNode("span", {
                          innerHTML: _ctx.getPaymentRefAndDate(item)
                        }, null, 8, _hoisted_11)
                      ]))
                    : _createCommentVNode("", true)
              ]),
              "item.PayRunInfo": _withCtx(({ item }) => [
                (item.payRun)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                      _createVNode(_component_v_btn, {
                        class: "primary",
                        small: "",
                        onClick: ($event: any) => (_ctx.showPayRun(item))
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode(" PayRun Details ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]),
                      (!item.payRun.adminApproved && !item.payRun.payments.length)
                        ? (_openBlock(), _createBlock(_component_v_icon, {
                            key: 0,
                            color: "orange",
                            onClick: ($event: any) => (_ctx.editPayRun(item))
                          }, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode("mdi-pencil")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (!item.isSum)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                        _createVNode(_component_v_icon, {
                          color: "green",
                          onClick: ($event: any) => (_ctx.newPayRun(item))
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("mdi-plus-circle")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["items", "headers", "loading", "custom-sort"])
          ]),
          _: 1
        }),
        (_openBlock(), _createBlock(_component_pay_run_form, {
          key: `payRun-${_ctx.payRunVersion}`,
          dateArray: [_ctx.model.FromDate, _ctx.model.ToDate],
          selectedPayRun: _ctx.selectedPayRun,
          isEditMode: _ctx.isEditModeForPayRun,
          showAddOrEdit: _ctx.showAddOrEditForPayRun,
          selectedTeammember: _ctx.selectedTeammember,
          paymentMethodDetail: _ctx.htmlText,
          onSetAddEditMode: _ctx.setAddEditMode
        }, null, 8, ["dateArray", "selectedPayRun", "isEditMode", "showAddOrEdit", "selectedTeammember", "paymentMethodDetail", "onSetAddEditMode"]))
      ]),
      _: 1
    }, 8, ["loading"]),
    (_ctx.selectedPayRun)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: `PDF-Modal-${_ctx.pdfVersion}`,
          "max-width": "800",
          modelValue: _ctx.showPdf,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showPdf) = $event)),
          persistent: "",
          onKeydown: _withKeys(_ctx.closePdfDialog, ["esc"])
        }, {
          default: _withCtx(() => [
            (_ctx.selectedPayRun)
              ? (_openBlock(), _createBlock(_component_PayRunDetail, {
                  key: `PDF-${_ctx.pdfVersion}`,
                  model: _ctx.selectedPayRun,
                  isForAdmin: true,
                  payrunAuditTrail: _ctx.payrunAuditTrail,
                  onConfirmed: _ctx.payRunConfirmed,
                  onClose: _ctx.closePdfDialog
                }, null, 8, ["model", "payrunAuditTrail", "onConfirmed", "onClose"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true),
    (_ctx.showSetRefrence)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 1,
          persistent: "",
          modelValue: _ctx.showSetRefrence,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showSetRefrence) = $event)),
          "max-width": "1200",
          onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showSetRefrence = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            (_ctx.showSetRefrence)
              ? (_openBlock(), _createBlock(_component_set_refrence, {
                  key: `payRun-${_ctx.payRunVersion}`,
                  selectedPayRun: _ctx.selectedPayRun,
                  teammemberName: `${_ctx.selectedTeammember?.FirstName} ${_ctx.selectedTeammember?.LastName}`,
                  onClose: _ctx.setPaymentConfirmed,
                  onConfirmed: _ctx.fetchPayRun
                }, null, 8, ["selectedPayRun", "teammemberName", "onClose", "onConfirmed"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.showPaymentMethodDetail)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 2,
          modelValue: _ctx.showPaymentMethodDetail,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showPaymentMethodDetail) = $event)),
          persistent: "",
          onKeydown: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.showPaymentMethodDetail = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  dark: "",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      dark: "",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showPaymentMethodDetail = false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("mdi-close")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createTextVNode("Payment method")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", { innerHTML: _ctx.htmlText }, null, 8, _hoisted_14)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}