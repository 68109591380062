import store from "@/store";
import UserInfo from "shared-components/src/models/UserInfo";
import { auth } from "shared-components/src/plugins/firebase";
import firebase from "firebase/compat/app";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";
import { defaultUser } from "@/store/state";
import ApiService from "./ApiService";
import UserServiceV2 from "shared-components/src/services/UserService";
import { UserModel } from "shared-components/src/services/openApi";

function firebaseConvertFirebaseUser2UserInfo(user: any): UserInfo {
  return {
    isAuthenticated: user.isAnonymous === false,
    id: user.uid || "-1",
    email: user.email || null,
    fullName: user.displayName || "",
    emailVerified: user.emailVerified || false,
    phoneNumber: user.phoneNumber || null,
    avatar: user.photoURL,
    title: "",
    lastSignInTime: null,
    name: "",
    surname: "",
    userName: user.email || null,
    roles: user.roles,
    creationDate: user.metadata.creationTime,
    customClaims: user.customClaims,
    ipDetail: null,
  } as UserInfo;
}

function convertUser2UserInfo(data: any): UserInfo {
  return {
    isAuthenticated: !data.isAnonymous || data.isAnonymous === false,
    id: data.user.id || "-1",
    email: data.user.email || null,
    fullName: data.user.displayName || "",
    emailVerified: data.user.emailVerified || false,
    phoneNumber: data.user.phoneNumber || null,
    avatar: data.user.photoUrl,
    title: "",
    lastSignInTime: null,
    name: "",
    surname: "",
    userName: data.user.email || null,
    roles: data.user.role,
    creationDate: data.user.creationDate,
    customClaims: "",
    ipDetail: data.ipDetail,
  } as UserInfo;
}

export default class UserService {
  public static async updateRole(role: UserSetRoleModel): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiService.put(`/authentication/SetUserRole`, role)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static async createUser(model: UserCreateModel): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/authentication/register`, model)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static async sendPushToken(token: any) {
    return new Promise((resolve, reject) => {
      ApiService.post("/authentication/SendUserDevice", { FirebaseToken: token, DeviceType: "Browser", Model: "" }).then((res) => {
        resolve(res.data);
      });
    });
  }
  public static getAllUsers(): Promise<UserModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/authentication/getUserList", "")
        .then((result) => {
          resolve(result.data as UserModel[]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async loginWithToken(): Promise<UserInfo> {
    const result = await ApiService.post(`/authentication/LoginWithCustomToken`, { Token: localStorage.idToken });
    if (!result.data) {
      return defaultUser;
    }
    let user: UserInfo;
    if (result.data) {
      try {
        user = await UserService.getUserData(result.data);
      } catch (error) {
        user = convertUser2UserInfo(result.data);
      }
    } else {
      user = defaultUser;
    }
    store.dispatch("setUserInfo", { userInfo: user, vm: null });
    return user;
  }

  public static async login(data: any): Promise<UserInfo> {
    let result;
    result = await UserServiceV2.login(data);
    if (!result.data) {
      return defaultUser;
    }
    if (result && result.data.user) {
      const token = result.data.jwtToken;
      localStorage.idToken = token;
      localStorage.refreshToken = result.data.refreshToken;
      //result = await ApiService.post(`/authentication/LoginWithCustomToken/true`, { Token: token });
      // if (!result.data.customClaims.roles || result.data.customClaims.roles.length === 0) {
      //   result.data.customClaims.roles = [] as string[];
      // }
    }
    let user: UserInfo;
    if (result.data) {
      try {
        user = await UserService.getUserData(result.data);
      } catch (error) {
        user = convertUser2UserInfo(result.data);
      }
    } else {
      user = defaultUser;
    }
    return user;
  }

  public static async reload(): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      if (auth.currentUser) {
        auth.currentUser
          .reload()
          .then((reloadResult) => {
            resolve(UserService.getUserInfo(auth.currentUser));
          })
          .catch((reloadError) => {
            reject(reloadError);
          });
      } else {
        reject("user not loggedin!");
      }
    });
  }

  public static async logout() {}

  public static async getUserInfo(user: any): Promise<UserInfo> {
    let userRecord;
    if (!user) {
      if (store.state.userInfo && store.state.userInfo.email) {
        userRecord = await ApiService.get(`/authentication/GetUserByEmail/${store.state.userInfo.email}`, "");
        if (!userRecord.data || userRecord.data.statusCode != SystemErrors.Authentication.Success || !userRecord.data.value) {
          return defaultUser;
        } else {
          return await UserService.getUserData(userRecord.data.value);
        }
      } else {
        return defaultUser;
      }
    } else {
      return await UserService.getFirebaseUserData(user);
    }
  }

  public static async sendEmailConfirmation() {
    if (auth.currentUser) {
      await auth.currentUser.sendEmailVerification();
    } else {
      throw new Error("user not loggedin!");
    }
  }

  public static resetPassword(email: string, callbackUrl: string): Promise<void> {
    return UserServiceV2.resetPassword(email, callbackUrl);
  }

  public static validateResetToken(token: string): Promise<string> {
    return UserServiceV2.validateResetToken(token);
  }

  public static saveNewPassword(password: string, token: string): Promise<string> {
    return UserServiceV2.saveNewPassword(password, token);
  }

  private static getCustomToken(token: string): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/user/getAdminToken/${token}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private static getFirebaseUserData(user: firebase.User): Promise<UserInfo> {
    let roles: string[] = [];
    return new Promise((resolve, reject) => {
      user.getIdTokenResult().then((tknRsult: any) => {
        roles = tknRsult.claims.roles;
        const retVal = firebaseConvertFirebaseUser2UserInfo(user);
        retVal.roles = `${roles}`;
        resolve(retVal);
      });
    });
  }

  private static getUserData(data: any): Promise<UserInfo> {
    let role = "";
    return new Promise((resolve, reject) => {
      if (data && data.user && data.user.role) {
        role = data.user.role;
      }
      const retVal = convertUser2UserInfo(data);
      retVal.roles = role;
      resolve(retVal);
    });
  }

  public static async getLoginLinkForClient(email: any) {
    let result = await ApiService.get(`/authentication/GetLoginLinkForClient/${email}`, "");
    return result.data;
  }
}
export interface UserCreateModel {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  customers: Array<{ id: string; Name: string }>;
}

export interface UserSetRoleModel {
  userId: string;
  role: string;
}
