import NotificationSharedModel from "shared-components/src/models/Notification";
import ApiService from "./ApiService";
import { messaging, firebaseVapidKey } from "shared-components/src/plugins/firebase";
import Utils from "shared-components/src/utils/Utils";
import { NotificationModel, NotificationItem, SendCommitmentNotificationRequestModel } from "shared-components/src/services/openApi";
import UserService from "./UserService";
import store from "@/store";

export default class NotificationService {
  public static async getById(id: string): Promise<NotificationSharedModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification/${id}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as NotificationSharedModel);
          } else {
            const item = result.data as NotificationSharedModel;
            resolve(item);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async GetByNotificationId(notificationId: string): Promise<NotificationItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification/items/${notificationId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              if (doc.SeenDate) {
                item.SeenDate = Utils.vsDateToDatetime(doc.SeenDate);
              }
              const retVal = { ...item } as NotificationItem;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getList(): Promise<NotificationSharedModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              if (doc.CreationDate) {
                item.CreationDate = Utils.vsDateToDatetime(doc.CreationDate);
              }
              if (doc.SeenDate) {
                item.SeenDate = Utils.vsDateToDatetime(doc.SeenDate);
              }
              if (doc.Schedule) {
                item.Schedule = Utils.vsDateToDatetime(doc.Schedule);
              }
              const retVal = { ...item } as NotificationSharedModel;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getByCommitmentId(commitmentId: string): Promise<NotificationItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification/getByCommitmentId/${commitmentId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: any): Promise<NotificationSharedModel> {
    return new Promise((resolve, reject) => {
      ApiService.post("/notification", model)
        .then((result) => {
          if (result.data) {
            model.id = result.data;
            resolve(model);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async sendCommitmentNotification(commitmentId: string, model: SendCommitmentNotificationRequestModel): Promise<NotificationModel> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/commitment/${commitmentId}/sendNotification`, model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(model: NotificationSharedModel): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put("/notification", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  /**
   * Requests notification permission and retrieves the Firebase Cloud Messaging (FCM) token.
   * @returns null.
   */
  public static async requestNotificationPermission(): Promise<void | null> {
    if (messaging && localStorage.idToken) {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const registration = await navigator.serviceWorker.ready;
          const currentToken = await messaging?.getToken({
            vapidKey: firebaseVapidKey,
            serviceWorkerRegistration: registration,
          });
          if (currentToken) {
            if (localStorage.firebaseToken !== currentToken) {
              await UserService.sendPushToken(currentToken);
              localStorage.firebaseToken = currentToken;
            }
            messaging?.onMessage((payload) => {
              store.commit("UPDATE_NOTIFICATION_LIST");
              const notificationTitle = payload.notification.title;
              const notificationOptions = {
                body: payload.notification.body,
                icon: payload.notification.icon,
              };
              this.showNotification(notificationTitle, notificationOptions);
            });
          } else {
            console.error("No registration token available. Request permission to generate one.");
          }
        } else if (permission === "default") {
          Notification.requestPermission((permission) => {
            if (permission === "granted") {
              messaging?.onMessage((payload) => {
                store.commit("UPDATE_NOTIFICATION_LIST");
                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                  body: payload.notification.body,
                  icon: payload.notification.icon,
                };
                this.showNotification(notificationTitle, notificationOptions);
              });
            }
          });
        } else {
          console.error("Unable to get permission to notify.");
        }
      } catch (error) {
        console.error("An error occurred while retrieving token. ", error);
      }
    }
  }

  /**
   * Checks if the notification permission has been granted.
   * @returns True if permission is granted, else false.
   */
  public static isNotificationPermissionGranted(): boolean {
    return Notification.permission === "granted";
  }

  public static showNotification(notificationTitle: string, notificationOptions: any): void {
    var notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = function () {
      window.focus();
    };
  }
}
